import React from 'react'
import { Link } from 'gatsby'

import EventIcon from '../../svg/event.svg'
import VideoIcon from '../../svg/video.svg'
import BlogIcon from '../../svg/blog.svg'

const SidebarCommunity = () => (
  <nav id="sidebar">
    <ul>
      <li>
        <Link activeClassName="active" to="/community/events/">
          <EventIcon className="eventIcon" /> Events
        </Link>
      </li>

      <li>
        <Link activeClassName="active" to="/community/videos/">
          <VideoIcon className="videoIcon" /> Videos
        </Link>
      </li>

      <li>
        <Link activeClassName="active" to="/community/blog/">
          <BlogIcon className="blogIcon" /> Blog
        </Link>
      </li>
    </ul>
  </nav>
)

export default SidebarCommunity
