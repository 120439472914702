import React from 'react'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeetup } from '@fortawesome/free-brands-svg-icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SidebarCommunity from '../../components/SidebarCommunity'

const EventsPage = () => (
  <Layout>
    <Helmet>
      <body className="community events" />
    </Helmet>

    <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
         description="The tools you need to build Proof of Process apps." />

    <SidebarCommunity />
    
    <main className="content">
      <h2>Events</h2>

      <div className="event">
        <FontAwesomeIcon icon={faMeetup} />
        <div class="description">
          <h3>Crypto Nights № 5</h3>
          <span>February 5, 2019, Paris</span>
          <a className="summary" href="https://www.meetup.com/fr-FR/Stratumn-Crypto-Nights-Paris/events/258263783/">See the summary</a>
        </div>
      </div>

      <div className="event">
        <FontAwesomeIcon icon={faMeetup} />
        <div class="description">
          <h3>Crypto Nights № 4</h3>
          <span>November 7, 2018, Paris</span>
          <a className="summary" href="https://www.meetup.com/fr-FR/Stratumn-Crypto-Nights-Paris/events/255363025/">See the summary</a>
        </div>
      </div>

      <div className="event">
        <FontAwesomeIcon icon={faMeetup} />
        <div class="description">
          <h3>Crypto Nights № 3</h3>
          <span>September 26, 2018, Paris</span>
          <a className="summary" href="https://www.meetup.com/fr-FR/Stratumn-Crypto-Nights-Paris/events/254139636/">See the summary</a>
        </div>
      </div>

      <div className="event">
        <FontAwesomeIcon icon={faMeetup} />
        <div class="description">
          <h3>Crypto Nights № 2</h3>
          <span>July 12, 2018, Paris</span>
          <a className="summary" href="https://www.meetup.com/fr-FR/Stratumn-Crypto-Nights-Paris/events/252020136/">See the summary</a>
        </div>
      </div>

      <div className="event">
        <FontAwesomeIcon icon={faMeetup} />
        <div class="description">
          <h3>Crypto Nights № 1</h3>
          <span>June 11, 2018, Paris</span>
          <a className="summary" href="https://www.meetup.com/fr-FR/Stratumn-Crypto-Nights-Paris/events/250803002/">See the summary</a>
        </div>
      </div>
    </main>
  </Layout>
)

export default EventsPage
